<template>
  <div class="PrintDeliveryNote p-lr-20 p-tb-20">
    <div id="dsaadsadsads" ref="PrintDeliveryNote">
      <template v-for="(item,index) in info">
        <div :key="index" :class="index %2==0 ? 'odd page ':'even page '" :style="{marginTop: index === 0 ? '' :'200px' }">
          <div class="page-header-title">交货单<!--交货单--></div>
          <div class="page-header">
            <div class="page-header-left">
              <div class="page-header-content">
                <span>交货单号<!--交货单号-->：{{ item.OrderDeliveryID }}</span>
                <span>入库日期<!--入库日期-->：{{ item.DeliveryArriveTime }}</span>
                <span>部门科室：{{ item.SIMSSectionName }}</span>
              </div>
            </div>
            <div class="page-header-right">
              <div class="page-header-qrcode">
                <!-- 二维码 -->
                <img :src="item.QrCodeBase64String" alt="二维码不见啦">
              </div>
            </div>
          </div>
          <div class="page-content  ">
            <div class="table A4">
              <table>
                <thead>
                  <tr>
                    <th style="width: 10%;">物料编号<!--物料编号--></th>
                    <th style="width: 30%;">物料名称<!--物料名称--></th>
                    <!-- @*  <th style="width: 10%;">品牌</th>
                  <th style="width: 20%;">规格</th>*@ -->
                    <th style="width: 8%;">数量(单位)<!--数量(单位)--></th>
                    <th style="width: 10%;">批号<!--批号--></th>
                    <th style="width: 10%;">效期<!--效期--></th>
                    <th style="width: 10%;">存放<!--存放--></th>
                    <th style="width:10%;">发票编号<!--发票编号--></th>
                    <th style="width:10%;">开票日期<!--开票日期--></th>
                  </tr>
                </thead>
                <tbody v-html="item.TableContent" />
              </table>
            </div>
          </div>
        </div>
      </template>

    </div>

    <div id="adsads" class="caozuo t-right p-top-20">
      <el-button type="primary" style="width:100px" @click="print">打印</el-button>
    </div>
  </div>
</template>
<script>

export default {
  name: 'PrintDeliveryNote1',
  data() {
    return {
      list: '',
      info: []
    }
  },
  mounted() {
    console.log(this.$route.params)
    // this.$route.params.tableData.forEach((item, index) => {
    //   this.list += item.IDX + '|'
    // })
    // this.list = this.list.slice(0, this.list.length - 1)
    this.list = this.$route.params.idx
    this.DeliveryDownloadQrcode()
  },
  methods: {
    printWithCss() {
      // Works with Chome, Firefox, IE, Safari
      // Get the HTML of div
      var title = '测试打印'
      var divElements = document.getElementById('dsaadsadsads').innerHTML
      console.log(divElements)
      var printWindow = window.open('', '_blank', '')
      // page-break-after: always;
      // open the window
      printWindow.document.open()
      // write the html to the new window, link to css file margin: 10px auto 0px auto;
      printWindow.document.write('<html><head><title>' + title + '</title>')
      printWindow.document.write(`<style type="text/css">
        .page {
          padding: 10px;
          height: auto;
          overflow: hidden;
}
.A4{
    page-break-before: auto;
    page-break-after: auto;
}

.odd {
    margin: 10px auto 0px auto;
}

.even {
    margin: 10px auto 0px auto;
    margin-top:40px;
}

.page-header {
    height: 100px;
    display: flex;
    padding:10px 10px 20px 10px;
    justify-content: space-between
}

.page-header-left {
    width: 70%;
    margin-bottom: 0px;
}

.page-header-title {
    display: block;
    letter-spacing: 20px;
    font-size: 18px;
    width: 100%;
    text-align: center;
    font-weight: bolder;
}


.page-header-content span {
    display: block;
    line-height: 1.8;
}

.page-header-right {
    display: inline-block;
}

.page-header-qrcode img {
    width: 100px;
    height: auto;
}

.page-content {
    width: 100%;
    padding:0 10px;
    box-sizing: border-box;
}

.page-content table {
    width: 100%;
    border-collapse: collapse;
    font-size: xx-small;
    border-spacing: 0px;
}

.page-content table thead th {
    font-weight: normal;
    border-style: solid;
    border-top: 0px;
    border-right: 0px;
    border-left: 0px;
    border-width: 2px;
}

.page-content table th,
td {
    height: auto;
    text-align: center;
    padding: 1px;
}

.page-content table tbody tr {
    border-bottom: 1px solid;
}
        </style>`
      )
      printWindow.document.write('</head><body>')
      printWindow.document.write(divElements)
      printWindow.document.write('</body></html>')
      printWindow.document.close()
      printWindow.focus()
      // The Timeout is ONLY to make Safari work, but it still works with FF, IE & Chrome.
      setTimeout(function() {
        printWindow.print()
        printWindow.close()
      }, 100)
    },
    print() {
      this.printWithCss()
    },
    DeliveryDownloadQrcode() {
      this.$api.DeliveryDownloadQrcode({ deliveryIDList: this.list }).then(res => {
        console.log(res)
        if (res.RetCode !== '0') return this.$message.error('错误：' + res.RetMsg)
        this.info = res.Message
      })
    }
  }
}
</script>
<style lang='scss' scoped>
@import url('../../style/test.scss');
</style>
